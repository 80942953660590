import { useState, useEffect } from "react";
import "./App.css";
import { useSearchParams } from "react-router-dom";
import Chatbot from "./components/Chatbot";
import { accessToken, appURL, isAppDomain, taggoaiUrl } from "./libs";
import { useApp } from "./AppContext";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { LanguageCode, validLanguages } from "./libs/translations";
const taggoai = document.getElementById("taggoai");
axios.defaults.headers.common["x-api-key"] = accessToken;
axios.defaults.headers.common[
  "Authorization"
] = `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`;

function App() {
  const taggoaiBotId = taggoai?.getAttribute("bot-id");
  let [searchParams] = useSearchParams();
  let botId = searchParams.get("botId") || taggoaiBotId;
  let shopId = searchParams.get("shopId");
  let platform = searchParams.get("platform");
  let isIframe = searchParams.get("isIframe");
  let start = searchParams.get("start");
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [lead, setLead] = useState();
  const [show, setShow] = useState(isAppDomain || isIframe || false);
  const { changeLanguage, setBot, language } = useApp();

  let url = `${appURL}/api/chatbot/id/${botId}`;
  const params = new URLSearchParams();
  const userLanguage: string = navigator.language;

  if (shopId) {
    params.append("shop_id", shopId);
  }

  if (platform) {
    params.append("platform", platform);
  }
  const locale = userLanguage ? userLanguage?.split("-")[0] : "en";
  const queryString = params.toString();
  if (queryString) {
    url += `?${queryString}&language=${locale}`;
  } else {
    url += `?language=${locale}`;
  }

  useEffect(() => {
    fetchData();
  }, [botId]);

  const fetchData = async () => {
    try {
      const response = await axios.get(url);
      const result = await response.data;
      setData(result);
      setLead(result.lead);

      const extractedLanguage = userLanguage?.split("-")[0] || 'en';

      // Check if the extracted language is a valid LanguageCode, if not, fallback to 'en'
      const language: LanguageCode = validLanguages.includes(extractedLanguage as LanguageCode)
        ? (extractedLanguage as LanguageCode)
        : 'en';

      changeLanguage(language); //user language
      setBot(result?.chatbot);
    } catch (error) {
      //console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const syncInit = () => {
      document.title = data?.name || "Taggo: AI Chatbot";
      return () => (document.title = "Taggo: AI Chatbot");
    };
    syncInit();
  }, [data]);

  if (loading) {
    return (
      <div className="first-loading">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className={`${isAppDomain ? "flex h-[100vh] items-center" : ""}`}>
      <div className={`${isMobile ? "mobile" : "app"}`}>
        <Chatbot
          botId={data.chatbot?._id}
          dataBot={data.chatbot}
          flows={(data?.flows && data?.flows.length > 0) ? data?.flows[0] : []}
          uuid={data.uuid}
          lead={lead}
          setLead={setLead}
          setShow={setShow}
          start={start ?? "home"}
        />
      </div>
    </div>
  );
}

export default App;
